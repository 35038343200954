import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionWrapHead,
  BoxSectionHeadingswrap,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
  BoxBottomImgWrap,
} from "./style";
import Button from "@ui/button";
const BoxSection = ({ data, layout, ...props }) => {
  return (
    <BoxSectionWrapper id={data.section} layout={layout} {...props}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap>
                {data &&
                  data.items.map((el, index) => (
                    <Anchor key={index} path={el.path} target="_blank">
                      <BoxSectionWrapInner>
                        <BoxSectionWrapHead>
                          <BoxSectionIconwrap>
                            <Image
                              src={el.images[0]?.src}
                              alt={el.images[0]?.alt || "Icon"}
                            />
                          </BoxSectionIconwrap>
                          <BoxSectionHeadingswrap>
                            <Heading as="h6">{el.subject}</Heading>
                            <Heading as="h4">{el.title}</Heading>
                          </BoxSectionHeadingswrap>
                        </BoxSectionWrapHead>
                        <BoxSectionTextWrap>
                          <Text>{el.description}</Text>
                        </BoxSectionTextWrap>
                      </BoxSectionWrapInner>
                    </Anchor>
                  ))}
              </BoxSectionWrap>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-5">
              {data?.buttons?.map(({ id,index, path, content, ...rest }) => (
                <Button key={index} path={path} {...rest}>
                  {content}
                </Button>
              ))}
            </Col>
          </Row>
          {!!data.images && (
            <Row>
              <Col>
                <BoxBottomImgWrap>
                  <Image
                    src={data.images[0]?.src}
                    alt={data.images[0]?.alt || "Image"}
                  />
                </BoxBottomImgWrap>
              </Col>
            </Row>
          )}
        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

BoxSection.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7]),
  items: PropTypes.object,
};
BoxSection.defaultProps = {
  layout: 1,
};

export default BoxSection;
